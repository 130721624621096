import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { getListingsById } from '../../ducks/marketplaceData.duck';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  AvatarSmall,
  InlineTextButton,
  IconFavorites,
  IconQuestion,
  IconShare,
  SocialSharePopup,
  Modal,
  IconPlusMinus,
  DraftContent
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionCustomDetailsMaybe from './SectionCustomDetailsMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
import SectionTabs from './SectionTabs';
import SectionBreadcrumbs from './SectionBreadcrumbs';
import SectionShopMoreFrom from './SectionShopMoreFrom';
import InquiryForm from './InquiryForm/InquiryForm';

import css from './ListingPage.module.css';
import classNames from 'classnames';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const { UUID, Money } = sdkTypes;

const IconRadioButton = props => {

  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <circle cx="9.5" cy="9.5" r="9" fill="#fff" stroke="#201F2266"></circle>
      <circle className={css.checked} cx="9.5" cy="9.5" r="6.5" fill="#201F22"></circle>
    </svg>
  );
};

export const ListingPageComponent = props => {

  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const [variantIndex, setVariantIndex] = useState(0)
  const [variantQuantity, setVariantQuantity] = useState(null)

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    allUserListings,
    allUserListingsInProgress
  } = props;

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook')?.slice(0,1);
  const twitterImages = listingImages(currentListing, 'twitter')?.slice(0,1);
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url)?.slice(0,1);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));
  const storeName = currentListing?.author?.attributes?.profile?.publicData?.storeName || authorDisplayName;

  const authorLink = currentAuthor?.id?.uuid && (
    <NamedLink
      className={css.authorNameLink}
      name="ProfilePage"
      params={{ id: currentAuthor?.id?.uuid }}
    >
      {storeName || authorDisplayName}
    </NamedLink>
  )

  const setCurrentVariant = (id, isSelected) => {
    setVariantIndex(id)
    !isSelected && setVariantQuantity(null)
  }

  // const setCurrentVariantQuantity = (e, value, operator) => {
  //   e.stopPropagation();
  //   let quantity = variantQuantity || value
  //   if (operator === "increment") setVariantQuantity(++quantity)
  //   else setVariantQuantity(--quantity)
  // }

  const productVariants = publicData?.variants;

  const productVariantsList = !!productVariants?.length && productVariants.map((v, i) => {
    const formattedPrice = formatMoney(intl, new Money(v.price, config.currency));
    const isSelected = variantIndex === i;

    return (
      <li
        key={v.id}
        className={classNames(css.variantItem, { [css.variantItemSelected]: isSelected })}
        onClick={() => setCurrentVariant(i, isSelected)}
      >
        <div className={css.variantItemContent}>
          <div className={css.variantItemHeading}>
            <div className={css.variantTitleHolder}>
              <h3 className={css.variantTitle}>
                <IconRadioButton className={css.customRadiobutton} />
                {v.variantTitle}
              </h3>
              <p className={css.variantPrice}>
                {formattedPrice}
              </p>
            </div>
          </div>

          <p className={css.variantOptions}>
            {v.options?.map(o => o.variantOption)?.join(", ")}
          </p>

          {/* <ul className={css.variantList}>
            <li className={css.variantListItem}>
              <span className={css.variantListItemTitle}>Quantity</span>

              <div className={css.variantQuantity}>
                {isSelected && variantQuantity > v.minimumOrderQuantity && (
                  <span
                    className={css.variantQuantityMinus}
                    onClick={(e) => setCurrentVariantQuantity(e, v.minimumOrderQuantity, 'decrement')}
                  >
                    <IconPlusMinus type="minus" />
                  </span>
                )}

                {isSelected ? (variantQuantity || v.minimumOrderQuantity) : v.minimumOrderQuantity}

                {isSelected && (
                  <span
                    className={css.variantQuantityPlus}
                    onClick={(e) => setCurrentVariantQuantity(e, v.minimumOrderQuantity, 'increment')}
                  >
                    <IconPlusMinus type="plus" />
                  </span>
                )}

              </div>
            </li>
            <li className={css.variantListItem}>
              <span className={css.variantListItemTitle}>Minimum order quantity</span>
              {v.minimumOrderQuantity}
            </li>
            <li className={css.variantListItem}>
              <span className={css.variantListItemTitle}>SKU</span>
              {v.sku}
            </li>
          </ul> */}
        </div>
      </li>
    )
  })

  const variantOptions = publicData?.variants?.map((v, i) => ({ value: i, label: v.variantTitle, variantOptions: v.options }))

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={null}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: null,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.topColumnForProductLayout}>
            <div className={css.galleryColumnForProductLayout}>

              <div className={css.mobileBreadcrumbs}>
                <SectionBreadcrumbs listing={currentListing} />
              </div>
              <div className={css.mobileHeading}>
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              </div>

              {currentListing.id ? (
                <ActionBarMaybe
                  className={css.actionBarForProductLayout}
                  isOwnListing={isOwnListing}
                  listing={currentListing}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingPathParamType,
                    tab: listingTab,
                  }}
                />
              ) : null}

              <SectionGallery
                listing={currentListing}
                variantPrefix={config.layout.listingImage.variantPrefix}
              />

              <div className={css.inlineButtons}>
                {/* <InlineTextButton
                  type="button"
                  onClick={() => alert("Wishlist")}
                  className={css.inlineButton}
                >
                  <IconFavorites size="big" />
                  <FormattedMessage id="ListingPage.wishlist" />
                </InlineTextButton> */}

                {!isOwnListing && (
                  <InlineTextButton
                    type="button"
                    onClick={() => onContactUser(currentListing.author)}
                    className={css.inlineButton}
                  >
                    <IconQuestion />
                    <FormattedMessage id="ListingPage.askQuestion" />
                  </InlineTextButton>
                )}

                <SocialSharePopup
                  reviews={reviews}
                  onManageDisableScrolling={onManageDisableScrolling}
                  listing={currentListing}
                  intl={intl}
                />
              </div>

              {authorLink && (
                <div className={css.author}>
                  <AvatarSmall
                    user={currentAuthor}
                    className={css.providerAvatar}
                    storeName={storeName}
                  />
                  <span className={css.providerNameLinked}>
                    <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
                  </span>
                </div>
              )}


              {/* {productVariants && (
                <ul className={css.variantList}>
                  {productVariantsList}
                </ul>
              )} */}
              {/* Mobile view - not popup */}
              <OrderPanel
                className={css.productOrderPanelMobile}
                listing={currentListing}
                isOwnListing={isOwnListing}
                onSubmit={handleOrderSubmit}
                title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
                titleDesktop={
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                }
                description={description}
                author={ensuredAuthor}
                onManageDisableScrolling={onManageDisableScrolling}
                onContactUser={onContactUser}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                validListingTypes={config.listing.listingTypes}
                marketplaceCurrency={config.currency}
                dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                marketplaceName={config.marketplaceName}
                authorDisplayName={authorDisplayName}
                isMobileView
                variantIndex={variantIndex}
                config={config}
                variantQuantity={variantQuantity}
                variantOptions={variantOptions}
                onSetVariantIndex={(i) => setVariantIndex(i.value)}
              />

              {description && (
                <p className={css.shortDescription}>
                  <DraftContent draftContent={description} />
                </p>
              )}
            </div>

            <div className={css.orderColumnForProductLayout}>
              <div className={css.orderColumnStickyWrapper}>
                <SectionBreadcrumbs listing={currentListing} />
                <OrderPanel
                  className={css.productOrderPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  onSubmit={handleOrderSubmit}
                  title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
                  titleDesktop={
                    <H4 as="h1" className={css.orderPanelTitle}>
                      <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                    </H4>
                  }
                  description={description}
                  author={ensuredAuthor}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onContactUser={onContactUser}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  validListingTypes={config.listing.listingTypes}
                  marketplaceCurrency={config.currency}
                  dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                  marketplaceName={config.marketplaceName}
                  authorDisplayName={authorDisplayName}
                  variantIndex={variantIndex}
                  config={config}
                  variantQuantity={variantQuantity}
                  variantOptions={variantOptions}
                  onSetVariantIndex={(i) => setVariantIndex(i.value)}
                />
              </div>
            </div>
          </div>

          <div className={css.mainColumnForProductLayout}>
            <div className={css.sectionDetails}>
              <SectionDetailsMaybe
                publicData={publicData}
                metadata={metadata}
                listingConfig={listingConfig}
                intl={intl}
              />

              {listingConfig.listingFields.reduce((pickedElements, config) => {
                const { key, enumOptions, scope = 'public' } = config;
                const value =
                  scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
                const hasValue = value !== null;
                return hasValue && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                  ? [
                    ...pickedElements,
                    <SectionMultiEnumMaybe
                      key={key}
                      heading={config?.showConfig?.label}
                      options={createFilterOptions(enumOptions)}
                      selectedOptions={value}
                    />,
                  ]
                  : hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                    ? [
                      ...pickedElements,
                      <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                    ]
                    : pickedElements;
              }, [])}
            </div>

            <SectionShopMoreFrom
              allUserListings={allUserListings}
              allUserListingsInProgress={allUserListingsInProgress}
              storeName={storeName}
              currentListingId={listingId.uuid}
            />

            <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />

            {/* <SectionTabs
              description={description}
              reviews={reviews}
              fetchReviewsError={fetchReviewsError}
              publicData={publicData}
            /> */}


            {/* <SectionTextMaybe text={description} showAsIngress /> */}


            {/* <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            /> */}
            {/* <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} /> */}
            {/* <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            /> */}
          </div>

        </div>

        <Modal
          id="ListingPage.inquiry"
          contentClassName={css.inquiryModalContent}
          isOpen={isAuthenticated && inquiryModalOpen}
          onClose={() => setInquiryModalOpen(false)}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <InquiryForm
            className={css.inquiryForm}
            submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
            listingTitle={title}
            authorDisplayName={authorDisplayName}
            sendInquiryError={sendInquiryError}
            onSubmit={onSubmitInquiry}
            inProgress={sendInquiryInProgress}
          />
        </Modal>

      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    allUserListingIds,
    allUserListingsInProgress,
    allUserListingsError,
  } = state.ListingPage;


  const allUserListings = !!allUserListingIds?.length && getListingsById(state, allUserListingIds) || [];

  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    allUserListings,
    allUserListingsInProgress,
    allUserListingsError
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
