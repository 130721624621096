import React from 'react';

import css from './ListingPage.module.css';

const SectionBreadcrumbs = props => {
  const {
    listing
  } = props;

  const category = listing?.attributes?.publicData?.category?.[0]?.label
  const title = listing?.attributes?.title


  return (
    <ul className={css.breadcrumbs}>
      <li className={css.breadcrumbsItem}>
        Home
      </li>
      {category ? (
        <li className={css.breadcrumbsItem}>
          {category}
        </li>
      ) : (
        <li className={css.breadcrumbsItem}>
          Uncategorized
        </li>
      )}
      <li className={css.breadcrumbsItem}>
        {title}
      </li>
    </ul>
  );
};

export default SectionBreadcrumbs;
