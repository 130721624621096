import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { ListingCard, IconArrowCarousel } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';

const MODAL_BREAKPOINT = 768;
const TABLET_BREAKPOINT = 1024;

export const SectionShopMoreFrom = props => {

  const { 
    isMapVariant, 
    allUserListings, 
    storeName, 
    currentListingId 
  } = props;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  const items = allUserListings?.filter(l => l.id.uuid !== currentListingId)?.map(l => (
    <ListingCard
      className={css.carouselCard}
      key={l.id.uuid}
      listing={l}
      renderSizes={cardRenderSizes(isMapVariant)}
      showAuthorInfo
    />
  ));

  const prevButton = () => {
    return <IconArrowCarousel direction="left" />
  }

  const nextButton = () => {
    return <IconArrowCarousel direction="right" />
  }

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
  const isTabletLayout = isWindowDefined && window.innerWidth < TABLET_BREAKPOINT;

  return !!items.length && (
    <div className={css.carouselRoot}>
      <h2 className={css.carouselTitle}>
        <FormattedMessage id="ListingPage.shopMoreFromTitle" values={{ storeName }} />
      </h2>
      <div className={css.carouselWrapper}>
        <AliceCarousel
          infinite={items?.length > 4}
          autoWidth={items?.length < 4 && !isTabletLayout}
          items={items}
          disableDotsControls={true}
          renderPrevButton={() => prevButton()}
          renderNextButton={() => nextButton()}
          responsive={{
            0: { items: 1 },
            768: { items: 2 },
            1024: { items: 4 }
          }}
        />
      </div>
    </div>
  );
}

export default SectionShopMoreFrom;