import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import SectionTextMaybe from './SectionTextMaybe';
import SectionReviews from './SectionReviews';
import SectionCustomDetailsMaybe from './SectionCustomDetailsMaybe';

import css from './ListingPage.module.css';

const REVIEWS = "reviews"
const DESCRIPTION = "description"
const MORE_INFORMATION = "moreInformation"

const SectionTabs = props => {
  const {
    reviews,
    fetchReviewsError,
    description,
    publicData
  } = props;

  const [activeTab, setActiveTab] = useState(DESCRIPTION)

  return (
    <div className={css.tabs}>

      <ul className={css.tabsNav}>
        <li
          className={classNames(css.tabsNavItem, { [css.tabsNavItemActive]: activeTab === REVIEWS })}
          onClick={() => setActiveTab(REVIEWS)}
        >
          Reviews
        </li>
        <li
          className={classNames(css.tabsNavItem, { [css.tabsNavItemActive]: activeTab === DESCRIPTION })}
          onClick={() => setActiveTab(DESCRIPTION)}
        >
          Description
        </li>
        <li
          className={classNames(css.tabsNavItem, { [css.tabsNavItemActive]: activeTab === MORE_INFORMATION })}
          onClick={() => setActiveTab(MORE_INFORMATION)}
        >
          More information
        </li>
      </ul>

      <div className={css.tabsHolder}>
        {activeTab === REVIEWS && (
          <div className={css.tabsItem}>
            <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
          </div>
        )}

        {activeTab === DESCRIPTION && (
          <div className={css.tabsItem}>
            <SectionTextMaybe text={description} showAsIngress />
          </div>
        )}

        {activeTab === MORE_INFORMATION && (
          <div className={css.tabsItem}>
            <SectionCustomDetailsMaybe publicData={publicData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionTabs;
